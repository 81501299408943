import React, { Component } from 'react';
import { Link } from 'react-router-dom';

const Header: React.FC = () => {    
    
    ///////////////////////////
    // render()
    ///////////////////////////
    return (
        <nav className="navbar navbar-custom navbar-fixed-top navbar-transparent" role="navigation">
        <div className="container">
        <div className="navbar-header">
            <button className="navbar-toggle" type="button" data-toggle="collapse" data-target="#custom-collapse"><span className="sr-only">Toggle navigation</span><span className="icon-bar"></span><span className="icon-bar"></span><span className="icon-bar"></span></button>
            <a className="navbar-brand" href="/">telligence</a>
        </div>
        <div className="collapse navbar-collapse" id="custom-collapse">
            <ul className="nav navbar-nav navbar-right">
                <li><Link to="/">Home</Link></li>
                <li><Link to="/">Why Us?</Link></li>
                <li><Link to="/">Practice Areas</Link></li>
                <li><Link to="/">Portfolio</Link></li>
                <li className="dropdown"><a className="dropdown-toggle" href="#" data-toggle="dropdown">Blog</a>
                    <ul className="dropdown-menu" role="menu">
                        <li><Link to="/blog/blog1">Blog Article #1</Link></li>
                        <li><Link to="/blog/blog2">Blog Article #2</Link></li>
                        <li><Link to="/blog/blog3">Blog Article #3</Link></li>
                    </ul>
                </li>
                <li><Link to="/">Contact Us</Link></li>
            </ul>
        </div>
        </div>
    </nav>
)
}
  
export default Header;
