import React, { Component, useState } from 'react';
import CountUp from 'react-countup';

const Stats: React.FC = () => {    
    
    ///////////////////////////
    // render()
    ///////////////////////////
    return (
        <section className="module bg-dark-60 parallax-bg" data-background="asset/img/funfact_bg.jpg" style={{backgroundPosition: "50% 15%"}}>
            <div className="container">
                <div className="row multi-columns-row">
                <div className="col-sm-6 col-md-3 col-lg-3">
                    <div className="count-item mb-sm-40">
                    <div className="count-icon"><i className="fa fa-regular fa-briefcase"></i></div>
                    <h3 className="count-to font-alt"><CountUp end={30} /> yrs</h3>
                    <h5 className="count-title font-serif">Industry Experience</h5>
                    </div>
                </div>
                <div className="col-sm-6 col-md-3 col-lg-3">
                    <div className="count-item mb-sm-40">
                    <div className="count-icon"><i className="fa fa-light fa-users"></i></div>
                    <h3 className="count-to font-alt"><CountUp end={200} />+</h3>
                    <h5 className="count-title font-serif">A/I Experts</h5>
                    </div>
                </div>
                <div className="col-sm-6 col-md-3 col-lg-3">
                    <div className="count-item mb-sm-40">
                    <div className="count-icon"><span className="icon-target"></span></div>
                    <h3 className="count-to font-alt"><CountUp end={50} />+</h3>
                    <h5 className="count-title font-serif">Successful Projects</h5>
                    </div>
                </div>
                <div className="col-sm-6 col-md-3 col-lg-3">
                    <div className="count-item mb-sm-40">
                    <div className="count-icon"><span className="icon-happy"></span></div>
                    <h3 className="count-to font-alt"><CountUp end={100} /> %</h3>
                    <h5 className="count-title font-serif">Customer Satisfaction</h5>
                    </div>
                </div>
                </div>
            </div>
        </section>
    )
}
  
export default Stats;
