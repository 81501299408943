import React, { Component, useState } from 'react';

const Portfolio: React.FC = () => {    
    
    ///////////////////////////
    // render()
    ///////////////////////////
    return (
        <section className="module sliding-portfolio">
            <div className="row">
                <div className="col-sm-6 col-sm-offset-3">
                <h2 className="module-title font-alt">Our Solved Cases</h2>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                <div className="owl-carousel text-center" data-items="4" data-pagination="false" data-navigation="false">
                    <div className="owl-item">
                    <div className="col-sm-12">
                        <div className="work-item"><a href="#">
                            <div className="work-image"><img src="asset/img/case1.jpg" alt="Portfolio Item"/></div>
                            <div className="work-caption font-alt">
                            <h3 className="work-title">Corporate Identity</h3>
                            <div className="work-descr">Illustration</div>
                            </div></a></div>
                    </div>
                    </div>
                    <div className="owl-item">
                    <div className="col-sm-12">
                        <div className="work-item"><a href="#">
                            <div className="work-image"><img src="asset/img/case2.jpg" alt="Portfolio Item"/></div>
                            <div className="work-caption font-alt">
                            <h3 className="work-title">Bag MockUp</h3>
                            <div className="work-descr">Marketing</div>
                            </div></a></div>
                    </div>
                    </div>
                    <div className="owl-item">
                    <div className="col-sm-12">
                        <div className="work-item"><a href="#">
                            <div className="work-image"><img src="asset/img/case3.jpg" alt="Portfolio Item"/></div>
                            <div className="work-caption font-alt">
                            <h3 className="work-title">Disk Cover</h3>
                            <div className="work-descr">Illustration</div>
                            </div></a></div>
                    </div>
                    </div>
                    <div className="owl-item">
                    <div className="col-sm-12">
                        <div className="work-item"><a href="#">
                            <div className="work-image"><img src="asset/img/case4.jpg" alt="Portfolio Item"/></div>
                            <div className="work-caption font-alt">
                            <h3 className="work-title">Corporate Identity</h3>
                            <div className="work-descr">Illustration</div>
                            </div></a></div>
                    </div>
                    </div>
                    <div className="owl-item">
                    <div className="col-sm-12">
                        <div className="work-item"><a href="#">
                            <div className="work-image"><img src="asset/img/case5.jpg" alt="Portfolio Item"/></div>
                            <div className="work-caption font-alt">
                            <h3 className="work-title">Bag MockUp</h3>
                            <div className="work-descr">Marketing</div>
                            </div></a></div>
                    </div>
                    </div>
                    <div className="owl-item">
                    <div className="col-sm-12">
                        <div className="work-item"><a href="#">
                            <div className="work-image"><img src="asset/img/case6.jpg" alt="Portfolio Item"/></div>
                            <div className="work-caption font-alt">
                            <h3 className="work-title">Disk Cover</h3>
                            <div className="work-descr">Illustration</div>
                            </div></a></div>
                    </div>
                    </div>
                </div>
                <div className="col-sm-12">
                    <div className="text-center"><a className="btn btn-border-d btn-circle mt-50" href="#">View All Cases</a></div>
                </div>
                </div>
            </div>
        </section>
    )
}
  
export default Portfolio;
