import React, { Component } from 'react';

import LayoutMaster from '../../layout/LayoutMaster';

import AboutUs from './AboutUs';
import Stats from './Stats';
import PracticeAreas from './PracticeAreas';
import Portfolio from './Portfolio';
import Callback from './Callback';
import Blog from './Blog';
import Clients from './Clients';
import ContactUs from './ContactUs';


class HomeMain extends Component {

    ///////////////////////////
    // componentDidMount()
    ///////////////////////////
    componentDidMount() { 
        //console.log('ContactMain.componentDidMount()');
    } 

    ///////////////////////////
    // render()
    ///////////////////////////
    render() { 

        return (
            <LayoutMaster>
                <AboutUs />
                <Stats />
                <PracticeAreas />
                <Portfolio />
                <Callback />
                <Blog />
                <Clients />
                <ContactUs />
            </LayoutMaster>
        );
    }

}

export default HomeMain;
