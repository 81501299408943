import React, { Component, useState } from 'react';

// Define the interface for the props
interface Props {
    type    : string;
    text    : string;
}

const Message: React.FC<Props> = (props) => {    

    const { type, text } = props;

    var icon    = '';
    var clsName = '';
    var level   = '';
    if (type == 'success') {
        clsName = 'alert alert-success';
        icon    = 'fa fa-cog fa-spin';
        level   = 'Success';
    } else if (type == 'info') {
        clsName = 'alert alert-info';
        icon    = 'fa fa-cutlery';
        level   = 'Info';
    } else if (type == 'warning') {
        clsName = 'alert alert-warning';
        icon    = 'fa fa-diamond';
        level   = 'Warning';
    } else if (type == 'error') {
        clsName = 'alert alert-danger';
        icon    = 'fa fa-coffee';
        level   = 'Error';
    }

    ///////////////////////////
    // render()
    ///////////////////////////
    return (
        <>
            {(text != '') ? (
                <div className={clsName} role="alert">
                    <button className="close" type="button" data-dismiss="alert" aria-hidden="true">&times;</button>
                    <i className={icon}></i><strong style={{paddingRight: '5px'}}>{level}!</strong>{text}
                </div>
            ) : (
                <div>no message</div>
            )}        
        </>
      )

}

export default Message;