import React, { Component, useState } from 'react';

const PracticeAreas: React.FC = () => {    
    
    ///////////////////////////
    // render()
    ///////////////////////////
    return (
        <section className="module pt-0 pb-0">
            <div className="row position-relative m-0">
                <div className="col-xs-12 col-md-6 side-image" data-background="asset/img/section-14.jpg"></div>
                <div className="col-xs-12 col-md-6 col-md-offset-6">
                <div className="row finance-image-content">
                    <div className="col-md-10 col-md-offset-1">
                    <h2 className="module-title font-alt align-left">Practice Areas</h2>
                    <div className="row multi-columns-row">
                        <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="alt-features-item">
                            <div className="alt-features-icon"><span className="icon-compass"></span></div>
                            <h3 className="alt-features-title font-alt">Strategy &amp; Roadmap</h3>
                            Aasses business goals, data assets, &amp; technical capabilities to create a customized AI roadmap.
                        </div>
                        <div className="alt-features-item">
                            <div className="alt-features-icon"><span className="icon-linegraph"></span></div>
                            <h3 className="alt-features-title font-alt">Business Optimization</h3>
                            Analyze existing workflows &amp; identify opportunities to automate tasks, improve decision-making, or predict outcomes through AI integration.
                        </div>
                        <div className="alt-features-item">
                            <div className="alt-features-icon"><span className="icon-layers"></span></div>
                            <h3 className="alt-features-title font-alt">Infrastructure</h3>
                            Build a robust data infrastructure crucial for effective AI implementation. Address data collection, storage and governance.
                        </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="alt-features-item">
                            <div className="alt-features-icon"><span className="icon-toolbox"></span></div>
                            <h3 className="alt-features-title font-alt">Platform Selection</h3>
                            Choose the right AI tools and platforms from the vast available options as it's very crucial for success. 
                        </div>
                        <div className="alt-features-item">
                            <div className="alt-features-icon"><span className="icon-puzzle"></span></div>
                            <h3 className="alt-features-title font-alt">Model Development</h3>
                            Collaborate with data scientists and engineers to develop, train, and deploy specific AI models essential for your use cases and business. 
                        </div>
                        <div className="alt-features-item">
                            <div className="alt-features-icon"><i className="icon-genius"></i></div>
                            <h3 className="alt-features-title font-alt">Integration / Automation</h3>
                            Connect AI models with existing systems and workflows utilizing custom development, smooth automation and integration.
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </section>
)
}
  
export default PracticeAreas;
