import React, { Component, useState } from 'react';

const AboutUs: React.FC = () => {    
    
    ///////////////////////////
    // render()
    ///////////////////////////
    return (
        <>
            <section className="module-extra-small bg-dark">
            <div className="container">
                <div className="row">
                <div className="col-sm-6 col-md-8 col-lg-6 col-lg-offset-2">
                    <div className="callout-text font-alt">
                    <h4>looking for a world-class A/I consultant?</h4>
                    <p>We are here... let's build your AI-enabled business!</p>
                    </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-2">
                    <div className="callout-btn-box"><a className="btn btn-w btn-round" href="#">Contact Us!</a></div>
                </div>
                </div>
            </div>
            </section>

            <section className="module-medium">
            <div className="container">
                <div className="row multi-columns-row">
                <div className="col-md-4 col-sm-6 col-xs-12">
                    <div className="content-box">
                        <div className="content-box-image"><img src="asset/img/finance1.jpg" alt="Consult With Us"/></div>
                        <h3 className="content-box-title font-serif">1. Consult With Us</h3>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Id pariatur molestiae illum cum facere deserunt a enim harum eaque fugit.
                    </div>
                </div>
                <div className="col-md-4 col-sm-6 col-xs-12">
                    <div className="content-box">
                        <div className="content-box-image"><img src="asset/img/finance2.jpg" alt="Tailor AI Solution"/></div>
                        <h3 className="content-box-title font-serif">2. Tailor/Implement AI Solution</h3>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Id pariatur molestiae illum cum facere deserunt a enim harum eaque fugit.
                    </div>
                </div>
                <div className="col-md-4 col-sm-6 col-xs-12">
                    <div className="content-box">
                        <div className="content-box-image"><img src="asset/img/finance3.jpg" alt="Grow Business Bigger"/></div>
                        <h3 className="content-box-title font-serif">3. Grow Business Bigger</h3>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Id pariatur molestiae illum cum facere deserunt a enim harum eaque fugit.
                    </div>
                </div>
                </div>
            </div>
            </section>
        </>
    )
}
  
export default AboutUs;
