import React, { Component, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { CONTACT_US_VALIDATION_FAILED, SEND_EMAIL_CONTACT_US } from '../../store/home/actions';
import Message from '../../component/Message';


interface Props {
    message     : string,
    messageType : string
  }

const ContactUs: React.FC<Props> = (props) => {    
    
    const dispatch = useDispatch();

    // handleChange()
    const [inputName, setInputName]         = useState('');
    const [inputEmail, setInputEmail]       = useState('');
    const [inputMessage, setInputMessage]   = useState('');

    const handleChangeName = (event:any) => {
        setInputName(event.target.value);
    };
    const handleChangeEmail = (event:any) => {
        setInputEmail(event.target.value);
    };
    const handleChangeMessage = (event:any) => {
        setInputMessage(event.target.value);
    };

    //////////////////////
    // handleContactUs()
    //////////////////////
    const handleContactUs = () => {
        console.log('handleContactUs()');

        if (inputName == null || inputName == '' ||
            inputEmail == null || inputEmail == '' ||
            inputMessage == null || inputMessage == '') {
            dispatch({
                type    : CONTACT_US_VALIDATION_FAILED,
                payload : {},
            });  
            return;
        };

        var data = {
            appName : process.env.REACT_APP_APP_NAME,
            name    : inputName,
            email   : inputEmail,
            message : inputMessage,
        };
        
        dispatch({
            type    : SEND_EMAIL_CONTACT_US,
            payload : data,
        });  
    };

    ///////////////////////////
    // render()
    ///////////////////////////
    return (
        <section className="module pt-0 pb-0" id="contact">
            <div className="row position-relative m-0">
                <div className="col-xs-12 col-md-6">
                <div className="row finance-image-content">
                    <div className="col-md-10 col-md-offset-1">
                    <h2 className="module-title font-alt align-left">Get in touch</h2>
                    <Message type={props.messageType} text={props.message} />
                    {/*<form id="contactForm">*/}
                        <div className="form-group">
                        <label className="sr-only" htmlFor="name">Name</label>
                        <input className="form-control" type="text" id="name" name="name" value={inputName} onChange={handleChangeName} placeholder="Your Name*" required={true} data-validation-required-message="Please enter your name."/>
                        <p className="help-block text-danger"></p>
                        </div>
                        <div className="form-group">
                        <label className="sr-only" htmlFor="email">Email</label>
                        <input className="form-control" type="email" id="email" name="email" value={inputEmail} onChange={handleChangeEmail} placeholder="Your Email*" required={true} data-validation-required-message="Please enter your email address."/>
                        <p className="help-block text-danger"></p>
                        </div>
                        <div className="form-group">
                        <textarea className="form-control" rows={7} id="message" name="message" value={inputMessage} onChange={handleChangeMessage} placeholder="Your Message*" required={true} data-validation-required-message="Please enter your message."></textarea>
                        <p className="help-block text-danger"></p>
                        </div>
                        <div className="text-center">
                            <button id="cfsubmit" onClick={handleContactUs} className="btn btn-block btn-round btn-d" >Submit</button>
                        </div>
                    {/*</form>*/}
                    <div className="ajax-response font-alt" id="contactFormResponse"></div>
                    </div>
                </div>
                </div>
                <div className="col-xs-12 col-md-6 col-md-offset-6 side-image p-0">
                <section id="map-section">
                    <div id="map"></div>
                </section>
                </div>
            </div>
        </section>
);
}

const mapStateToProps = (state: any) => ({
    message     : state.homeReducer.message,
    messageType : state.homeReducer.messageType,
});
  
//export default ContactUs;
export default connect(mapStateToProps)(ContactUs);
