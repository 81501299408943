import React, { Component, useState } from 'react';

const Blog: React.FC = () => {    
    
    ///////////////////////////
    // render()
    ///////////////////////////
    return (
        <section className="module" id="news">
            <div className="container">
                <div className="row">
                <div className="col-sm-6 col-sm-offset-3">
                    <h2 className="module-title font-alt">Latest blog posts</h2>
                    <div className="module-subtitle font-serif">
                        In a world where progress is measured in leaps and bounds, the driving force behind innovation has taken a quantum leap into the realm of Artificial Intelligence. 
                        Welcome to Telligence.ai, where the future unfolds through seamless integration of cutting-edge technology and our expertise. 
                    </div>
                </div>
                </div>
                <div className="row multi-columns-row post-columns">
                <div className="col-sm-6 col-md-4 col-lg-4">
                    <div className="post mb-20">
                    <div className="post-thumbnail"><a href="#"><img src="asset/img/post-1.jpg" alt="Blog-post Thumbnail"/></a></div>
                    <div className="post-header font-alt">
                        <h2 className="post-title"><a href="#">Blog Post #1</a></h2>
                        <div className="post-meta">By&nbsp;<a href="#">Telligence</a>&nbsp;| 23 November
                        </div>
                    </div>
                    <div className="post-entry">
                        <p>
                            As you navigate through our blog, you'll delve into the heart of AI innovation, exploring the myriad ways it is reshaping industries and revolutionizing the way we live and work. 
                        </p>
                    </div>
                    <div className="post-more"><a className="more-link" href="#">Read more</a></div>
                    </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4">
                    <div className="post mb-20">
                    <div className="post-thumbnail"><a href="#"><img src="asset/img/post-2.jpg" alt="Blog-post Thumbnail"/></a></div>
                    <div className="post-header font-alt">
                        <h2 className="post-title"><a href="#">Blog Post #2</a></h2>
                        <div className="post-meta">By&nbsp;<a href="#">Telligence</a>&nbsp;| 11 November
                        </div>
                    </div>
                    <div className="post-entry">
                        <p>
                            As you navigate through our blog, you'll delve into the heart of AI innovation, exploring the myriad ways it is reshaping industries and revolutionizing the way we live and work. 
                        </p>
                    </div>
                    <div className="post-more"><a className="more-link" href="#">Read more</a></div>
                    </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4">
                    <div className="post mb-20">
                    <div className="post-thumbnail"><a href="#"><img src="asset/img/post-3.jpg" alt="Blog-post Thumbnail"/></a></div>
                    <div className="post-header font-alt">
                        <h2 className="post-title"><a href="#">Blog Post #3</a></h2>
                        <div className="post-meta">By&nbsp;<a href="#">Telligence</a>&nbsp;| 5 November
                        </div>
                    </div>
                    <div className="post-entry">
                        <p>
                            As you navigate through our blog, you'll delve into the heart of AI innovation, exploring the myriad ways it is reshaping industries and revolutionizing the way we live and work. 
                        </p>
                    </div>
                    <div className="post-more"><a className="more-link" href="#">Read more</a></div>
                    </div>
                </div>
                </div>
            </div>
        </section>
    )
}
  
export default Blog;
