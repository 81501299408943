import React, { Component } from 'react';
import $ from 'jquery';

import Header from './Header';
import Landing from './Landing';
import Footer from './Footer';

type Props = {
    children: React.ReactNode
 }

class LayoutMaster extends Component<Props> {
    
    ///////////////////////////
    // componentDidMount()
    ///////////////////////////
    componentDidMount() { 
        //console.log('LayoutMaster.componentDidMount()');
    } 

    
    ///////////////////////////
    // render()
    ///////////////////////////
    render() { 

        const imgLandingBG = '/asset/img/landing_bg.jpg';
        const styleLanding = {
            backgroundImage     : `url(${imgLandingBG})`,
            backgroundSize      : "cover",
            backgroundPosition  : "center",
        };

        return (
            <>
                <main>
                <div className="xpage-loader">
                    <div className="loader">Loading...</div>
                </div>

                <Header />

                <Landing />

                <div className="main">

                    {this.props.children}

                    <Footer />

                </div>
                <div className="scroll-up"><a href="#totop"><i className="fa fa-angle-double-up"></i></a></div>
                </main>
            </>
        );
    }
}

export default LayoutMaster;
