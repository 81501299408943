import React, { Component, useState } from 'react';

const Clients: React.FC = () => {    
    
    ///////////////////////////
    // render()
    ///////////////////////////
    return (
        <section className="module-small bg-dark p-0">
            <div className="container">
                <div className="row client">
                <div className="owl-carousel text-center" data-items="6" data-pagination="false" data-navigation="false">
                    <div className="owl-item">
                    <div className="col-sm-12">
                        <div className="client-logo"><img src="asset/img/client-logo-1.png" alt="Client Logo"/></div>
                    </div>
                    </div>
                    <div className="owl-item">
                    <div className="col-sm-12">
                        <div className="client-logo"><img src="asset/img/client-logo-2.png" alt="Client Logo"/></div>
                    </div>
                    </div>
                    <div className="owl-item">
                    <div className="col-sm-12">
                        <div className="client-logo"><img src="asset/img/client-logo-3.png" alt="Client Logo"/></div>
                    </div>
                    </div>
                    <div className="owl-item">
                    <div className="col-sm-12">
                        <div className="client-logo"><img src="asset/img/client-logo-4.png" alt="Client Logo"/></div>
                    </div>
                    </div>
                    <div className="owl-item">
                    <div className="col-sm-12">
                        <div className="client-logo"><img src="asset/img/client-logo-5.png" alt="Client Logo"/></div>
                    </div>
                    </div>
                    <div className="owl-item">
                    <div className="col-sm-12">
                        <div className="client-logo"><img src="asset/img/client-logo-1.png" alt="Client Logo"/></div>
                    </div>
                    </div>
                    <div className="owl-item">
                    <div className="col-sm-12">
                        <div className="client-logo"><img src="asset/img/client-logo-2.png" alt="Client Logo"/></div>
                    </div>
                    </div>
                    <div className="owl-item">
                    <div className="col-sm-12">
                        <div className="client-logo"><img src="asset/img/client-logo-3.png" alt="Client Logo"/></div>
                    </div>
                    </div>
                    <div className="owl-item">
                    <div className="col-sm-12">
                        <div className="client-logo"><img src="asset/img/client-logo-4.png" alt="Client Logo"/></div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </section>
)
}
  
export default Clients;
