import React, { Component, useState } from 'react';

const Landing: React.FC = () => {    
    
    ///////////////////////////
    // render()
    ///////////////////////////
    return (
        <section className="home-section bg-dark-30" id="home" data-background="asset/img/header_bg.png">
            <div className="video-player" data-property="{videoURL:'https://www.youtube.com/watch?v=uzlUiiH9IFo', containment:'.home-section', startAt:30, mute:false, autoPlay:true, loop:true, opacity:1, showControls:false, showYTLogo:false, vol:25}"></div>
                <div className="video-controls-box">
                    <div className="container">
                        <div className="video-controls"><a className="fa fa-volume-up" id="video-volume" href="#">&nbsp;</a><a className="fa fa-pause" id="video-play" href="#">&nbsp;</a></div>
                    </div>
                </div>
                <div className="titan-caption">
                <div className="caption-content">
                    <div className="font-alt mb-30 titan-title-size-1">we are humans! but...</div>
                    <div className="font-alt mb-40 titan-title-size-4">We know A/I</div><a className="section-scroll btn btn-border-w btn-round" href="#about">Learn More</a>
                </div>
            </div>
        </section>
    )
}
  
export default Landing;


