import React, { Component, useState } from 'react';

const Callback: React.FC = () => {    
    
    ///////////////////////////
    // render()
    ///////////////////////////
    return (
        <section className="module bg-dark-60 request-cta" data-background="asset/img/rqst_bg.jpg">
            <div className="container">
                <div className="row">
                <div className="col-sm-4">
                    <h2 className="font-alt">Request a call back</h2>
                    <p>
                        Unlock the potential of AI-driven innovation for your business! 
                        Our experts are here to discuss tailored solutions that streamline processes, enhance efficiency, and drive growth.
                        Just submit your details and we’ll be in touch shortly. 
                        </p>
                </div>
                <div className="col-sm-8">
                    <div className="row">
                    <form className="form rqst-form" id="requestACall" role="form" method="post" action="php/request_call.php">
                        <div className="form-group col-sm-6 col-xs-12">
                        <input className="form-control input-lg" type="text" name="name" placeholder="Name"/>
                        </div>
                        <div className="form-group col-sm-6 col-xs-12">
                        <select className="form-control input-lg" name="subject">
                            <option value="subject1" disabled={false} selected={true}>Subject</option>
                            <option value="BusinessConsulting">Business consulting</option>
                            <option value="MarketingStrategy">Marketing Strategy</option>
                            <option value="TaxesAdvisory">Taxes Advisory</option>
                            <option value="InvestmentPlanning">Investment Planning</option>
                            <option value="ITManagement">IT Management</option>
                            <option value="DataAnalytics">Data Analytics</option>
                        </select>
                        </div>
                        <div className="form-group col-sm-6 col-xs-12">
                        <input className="form-control input-lg" type="text" name="phone" placeholder="Phone Number"/>
                        </div>
                        <div className="form-group col-sm-6 col-xs-12">
                        <button className="btn btn-border-w btn-circle btn-block" id="racSubmit" type="submit"><i className="fa fa-paper-plane-o"></i> Submit</button>
                        </div>
                        <div id="requestFormResponse"></div>
                    </form>
                    </div>
                </div>
                </div>
            </div>
        </section>
    )
}
  
export default Callback;
